<template>
    <modal ref="modalAddOC" titulo="Agregar materiales de la orden de compra" tamano="modal-lg" adicional="Agregar" @adicional="createOrder">
        <ValidationObserver ref="validator">
            <div class="row my-3 mx-0 f-14">
                <div v-if="model.id_proveedor != null" class="col-12 my-3">
                    <p class="input-label-top">Proveedor</p>
                    <el-select v-model="model.id_proveedor" disabled size="small" placeholder="" class="w-100">
                        <el-option v-for="item in proveedor" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div v-else class="col-12 my-3">
                    <p class="input-label-top">Bodega</p>
                    <el-select v-model="model.id_bodega" disabled size="small" placeholder="" class="w-100">
                        <el-option v-for="item in bodega" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Pedido</p>
                    <ValidationProvider name="pedido" v-slot="{ errors }" rules="required">
                        <el-select v-model="model.id_pedido" filterable placeholder="Seleccionar pedido" size="small" class="w-100" @change="listarMateriales">
                            <el-option v-for="item in pedidos" :key="item.id" :label="item.pedido" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Materiales</p>
                    <ValidationProvider name="materiales" v-slot="{ errors }" rules="required">
                        <el-select v-model="model.materiales" multiple filterable placeholder="Seleccionar materiales" size="small" class="w-100">
                            <el-option v-for="item in pedidos_internos_materiales" :key="item.id" :label="item.material" :value="item.id" :disabled="materialesOcs.includes(item.id)" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            model:{
                id_bodega:null,
                id_proveedor:null,
                materiales:[],
                id_pedido:'',
                tipo:null
            },
            materialesOcs: [],
            radioResponsable: '1',
            proveedor:[],
            bodega:[],
        }
    },
    computed: {
        ...mapGetters({
            pedidos: 'almacen/pedidosInternosOcs/pedidos',
            pedidos_internos_materiales:'almacen/pedidosInternosOcs/pedidos_internos_materiales',

        }),
    },
    methods: {
        ...mapActions({
            Action_get_ocs_pedidos: 'almacen/pedidosInternosOcs/Action_get_ocs_pedidos',
            Action_get_materiales_pedidos_internos: 'almacen/pedidosInternosOcs/Action_get_materiales_pedidos_internos',
            Action_informacion_detalle_ocs: 'almacen/pedidosInternosOcs/Action_informacion_detalle_ocs',


        }),
        async toggle(){
            this.clean()
            this.$refs.modalAddOC.toggle()
            await this.listarInformacion()
        },
        async listarInformacion(){
            await this.Action_get_ocs_pedidos()
            const detalleOcs = JSON.parse(localStorage.getItem('detalleOcs'))
            this.materialesOcs = detalleOcs.materiales.map(e => e.id)
            this.model.id_proveedor = detalleOcs.proveedor != null ? detalleOcs.proveedor.id : null
            this.model.id_bodega = detalleOcs.bodega != null ? detalleOcs.bodega.id : null
            if(this.model.id_proveedor != null) this.proveedor.push(detalleOcs.proveedor)
            if(this.model.id_bodega != null) this.bodega.push(detalleOcs.bodega)          

        },
        async getMaterials(id_categoria){
            console.log('id_categoria=>',id_categoria);
        },
        async listarMateriales(id_pedido){
            await this.Action_get_materiales_pedidos_internos({id_pedido_interno:id_pedido})
            
        },
        async getEspesores(id_material){
            console.log('id_material=>',id_material);
        },
        async createOrder(){
            const valid = await this.$refs.validator.validate()
            if (!valid) return 
            if (this.model.id_proveedor == null) {
                this.model.tipo = 0
            }else{
                this.model.tipo = 1
            }
            this.model.materiales = this.model.materiales.concat(this.materialesOcs)
            this.$refs.modalAddOC.toggle()
            await this.Action_informacion_detalle_ocs(this.model)
            this.$emit('actilizarListaMateriales')
        },
        clean(){
            this.model.id_bodega = null,
            this.model.id_proveedor = null,
            this.model.materiales = [],
            this.model.id_pedido = '',
            this.model.tipo = null,
            this.materialesOcs = [],
            this.proveedor = [],
            this.bodega = []
        }
    }
}
</script>

<style lang="css" scoped>
</style>
