<template>
    <modal ref="modalSentOC" titulo="Enviar orden de compra" tamano="modal-lg" adicional="Aceptar" @adicional="enviarOc">
        <div class="row my-3 mx-0 f-14">
            <p class="f-14 f-600 text-general">Resumen de las ordenes de compra</p>
        </div>
        <div class="row bg-f5 py-3 my-4">
            <div class="col-4 f-12">
                <p class="text-general mb-1 f-600">Equipos:</p>
                <div v-for="(item, index) in detalleOcs.equipos" :key="index">
                    <p>{{item.nombre}}</p>
                </div>
            </div>
            <div class="col-4 f-12">
                <p class="text-general mb-1 f-600">Proyectos:</p>
                <div v-for="item in detalleOcs.proyectos" :key="item.id" >
                    <p class="tres-puntos">{{ item.nombre }}</p>
                </div>
            </div>
            <div class="col-4 f-12">
                <p class="text-general mb-1 f-600">Solicitantes:</p>
                <div v-for="item in detalleOcs.solicitantes" :key="item.id" >
                    <p>{{item.nombre}}</p>
                </div>
            </div>
        </div>
        <div class="row bg-f9 shadow29 my-3">
            <!-- <p class="col-12 text-center text-general f-14 f-600"> {{ detalleOcs.proveedor.nombre }} </p> -->
        </div>
        <div class="row mx-0 f-600 text-general f-12 border-bottom-g">
            <div class="col-6">
                <p>Nombre material</p>
            </div>
            <div class="col-2">
                <p>C. materiales</p>
            </div>
            <div class="col-2">
                <p>Valor unitario</p>
            </div>
            <div class="col-2">
                <p>Valor total</p>
            </div>
        </div>
        <div v-for="item in detalleOcs.materiales" :key="item.id" class="row mx-0 f-12 my-3">
            <div class="col-6">
                <p class="tres-puntos" >{{ item.material }}</p>
            </div>
            <div class="col-2">
                <p>{{ item.cantidad }}</p>
            </div>
            <div class="col-2">
                <p>{{ item.valor_unitario || '0'}}</p>
            </div>
            <div class="col-2">
                <p>{{ item.cantidad * item.valor_unitario }}</p>
            </div>
        </div>
        <div class="row mx-0 justify-content-end my-3">
            <div class="col-auto">
                <div class="border-general f-12 d-flex p-2 br-4">
                    <p class="f-600 text-general mr-3">Valor total</p>
                    <p>{{ valorTotal() }}</p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data(){
        return {
            detalleOcs:{},
            model:{
                tipo:null,
                id_proveedor:null,
                id_bodega:null,
                materiales:[]
            }
        }
    },
    computed: {
        ...mapGetters({
            nuevaOcs: 'almacen/pedidosInternosOcs/nuevaOcs',
        }),
    },
    methods: {
        ...mapActions({
            Action_post_materiales_pedidos_internos: 'almacen/pedidosInternosOcs/Action_post_materiales_pedidos_internos',
        }),
        toggle(){
            this.$refs.modalSentOC.toggle()
            this.detalleOcs = JSON.parse(localStorage.getItem('detalleOcs'))
        },
        async enviarOc(){
            this.model.materiales = this.detalleOcs.materiales.map(el => ({"id": el.id, "valor": el.valor_unitario}))
            if (this.detalleOcs.bodega == null) {
                this.model.tipo = 0
                this.model.id_proveedor = this.detalleOcs.proveedor.id
            }else{
                this.model.tipo = 1
                this.model.id_bodega = this.detalleOcs.bodega.id
            }
            await this.Action_post_materiales_pedidos_internos(this.model)
            this.$refs.modalSentOC.toggle()
            localStorage.removeItem('detalleOcs');
            this.$router.push({ name: 'almacen.pedidos.ver.orden.compra', params: { id: this.nuevaOcs.id}});

        },
        valorTotal(){
            let valorTotal = 0
            if (!this.detalleOcs.materiales) return 0 
            this.detalleOcs.materiales.forEach(el => {
                valorTotal += el.cantidad * el.valor_unitario
            });
            return valorTotal
        },

    }
}
</script>

<style lang="css" scoped>
</style>
